
  // @ts-ignore -- Unused import
  import * as EntityClasses from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceModels.g';
  import * as Entities from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
  // @ts-ignore -- Unused import
  import { jsonLightReadStringPropertyValue } from '@msdyn365-commerce/retail-proxy/dist/externals';

  
    /**
    * GuaranteedItemResponse entity interface.
    */
    export interface IGuaranteedItemResponse {
    IsGuaranteed?: boolean;
    }
  
    /**
    * GetMultiUnitOfMeasureConversionResponse entity interface.
    */
    export interface IGetMultiUnitOfMeasureConversionResponse {
    UomConvMap?: IUnitOfMeasureConversionResponseMapEntity[];
    }
  
    /**
    * GetSingleUnitOfMeasureConversionResponse entity interface.
    */
    export interface IGetSingleUnitOfMeasureConversionResponse {
    ItemRecId?: number;
    UnitMultipliedPrice?: number;
    FyiPrice?: number;
    SmallUomDescription?: string;
    LargeUomDescription?: string;
    ConversionFactor?: number;
    FyiConversionFactor?: number;
    SmallUomSymbol?: string;
    LargeUomSymbol?: string;
    FyiSymbol?: string;
    FyiDescription?: string;
    EcommPriceUnit?: number;
    DefaultDescription?: string;
    Product?: Entities.SimpleProduct;
    }
  
    /**
    * UnitOfMeasureConversionResponseMapEntity entity interface.
    */
    export interface IUnitOfMeasureConversionResponseMapEntity {
    ItemRecId: number;
    UnitMultipliedPrice: number;
    FyiPrice: number;
    SmallUomDescription?: string;
    LargeUomDescription?: string;
    ConversionFactor: number;
    FyiConversionFactor: number;
    SmallUomSymbol?: string;
    LargeUomSymbol?: string;
    FyiSymbol?: string;
    FyiDescription?: string;
    EcommPriceUnit: number;
    DefaultDescription?: string;
    Product?: Entities.SimpleProduct;
    }
  
    /**
    * GetStockStatusResponse entity interface.
    */
    export interface IGetStockStatusResponse {
    StockStatus?: string;
    }
  
    /**
    * CustomerWarehouseEntity entity interface.
    */
    export interface ICustomerWarehouseEntity {
    AvailableWarehouse?: string;
    WarehouseName?: string;
    ExtensionProperties?: Entities.CommerceProperty[];
    }
  
    /**
    * StockHoldStatusEntity entity interface.
    */
    export interface IStockHoldStatusEntity {
    Quantity?: number;
    InventHoldStatus?: string;
    }
  
    /**
    * GetCustomerWarehousesResponse entity interface.
    */
    export interface IGetCustomerWarehousesResponse {
    AvailableWarehouses?: ICustomerWarehouseEntity[];
    }
  
    /**
    * GetStockHoldStatusResponse entity interface.
    */
    export interface IGetStockHoldStatusResponse {
    StockHoldStatuses?: IStockHoldStatusEntity[];
    }
  
  /**
   * GuaranteedItemResponse entity class.
   */
  export class GuaranteedItemResponseExtensionClass implements IGuaranteedItemResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public IsGuaranteed: boolean;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.IsGuaranteed = odataObject.IsGuaranteed;
              
      }
  }

  /**
   * GetMultiUnitOfMeasureConversionResponse entity class.
   */
  export class GetMultiUnitOfMeasureConversionResponseExtensionClass implements IGetMultiUnitOfMeasureConversionResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public UomConvMap: 
            IUnitOfMeasureConversionResponseMapEntity[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.UomConvMap = undefined;
        if (odataObject.UomConvMap) {
          this.UomConvMap = [];
          for (var i = 0; i < odataObject.UomConvMap.length; i++) {
            if (odataObject.UomConvMap[i]) {
        if (odataObject.UomConvMap[i]['@odata.type']) {
          var className: string = odataObject.UomConvMap[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.UomConvMap[i] = new EntityClasses[className](odataObject.UomConvMap[i])
          }
        } else {
          this.UomConvMap[i] = new UnitOfMeasureConversionResponseMapEntityExtensionClass(odataObject.UomConvMap[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.UomConvMap[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * GetSingleUnitOfMeasureConversionResponse entity class.
   */
  export class GetSingleUnitOfMeasureConversionResponseExtensionClass implements IGetSingleUnitOfMeasureConversionResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemRecId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public UnitMultipliedPrice: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiPrice: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SmallUomDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LargeUomDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ConversionFactor: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiConversionFactor: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SmallUomSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LargeUomSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EcommPriceUnit: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DefaultDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Product: Entities.SimpleProduct;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemRecId = (odataObject.ItemRecId) ? parseInt(odataObject.ItemRecId, 10) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.UnitMultipliedPrice = (odataObject.UnitMultipliedPrice) ? parseFloat(odataObject.UnitMultipliedPrice) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiPrice = (odataObject.FyiPrice) ? parseFloat(odataObject.FyiPrice) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SmallUomDescription = odataObject.SmallUomDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LargeUomDescription = odataObject.LargeUomDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ConversionFactor = (odataObject.ConversionFactor) ? parseFloat(odataObject.ConversionFactor) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiConversionFactor = (odataObject.FyiConversionFactor) ? parseFloat(odataObject.FyiConversionFactor) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SmallUomSymbol = odataObject.SmallUomSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LargeUomSymbol = odataObject.LargeUomSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiSymbol = odataObject.FyiSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiDescription = odataObject.FyiDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EcommPriceUnit = (odataObject.EcommPriceUnit) ? parseFloat(odataObject.EcommPriceUnit) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DefaultDescription = odataObject.DefaultDescription;
              
        if (odataObject.Product === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Product = undefined;
        } else if (odataObject.Product['@odata.type'] == null) {
          this.Product = new EntityClasses.SimpleProductClass(odataObject.Product);
        } else {
          var className: string = odataObject.Product['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Product = new EntityClasses[className](odataObject.Product)
          }
        }

      
      }
  }

  /**
   * UnitOfMeasureConversionResponseMapEntity entity class.
   */
  export class UnitOfMeasureConversionResponseMapEntityExtensionClass implements IUnitOfMeasureConversionResponseMapEntity {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ItemRecId: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public UnitMultipliedPrice: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiPrice: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SmallUomDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LargeUomDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ConversionFactor: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiConversionFactor: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public SmallUomSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public LargeUomSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiSymbol: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public FyiDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public EcommPriceUnit: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public DefaultDescription: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Product: Entities.SimpleProduct;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ItemRecId = (odataObject.ItemRecId) ? parseInt(odataObject.ItemRecId, 10) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.UnitMultipliedPrice = (odataObject.UnitMultipliedPrice) ? parseFloat(odataObject.UnitMultipliedPrice) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiPrice = (odataObject.FyiPrice) ? parseFloat(odataObject.FyiPrice) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SmallUomDescription = odataObject.SmallUomDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LargeUomDescription = odataObject.LargeUomDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.ConversionFactor = (odataObject.ConversionFactor) ? parseFloat(odataObject.ConversionFactor) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiConversionFactor = (odataObject.FyiConversionFactor) ? parseFloat(odataObject.FyiConversionFactor) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.SmallUomSymbol = odataObject.SmallUomSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.LargeUomSymbol = odataObject.LargeUomSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiSymbol = odataObject.FyiSymbol;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.FyiDescription = odataObject.FyiDescription;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.EcommPriceUnit = (odataObject.EcommPriceUnit) ? parseFloat(odataObject.EcommPriceUnit) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.DefaultDescription = odataObject.DefaultDescription;
              
        if (odataObject.Product === null) {
          // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
          this.Product = undefined;
        } else if (odataObject.Product['@odata.type'] == null) {
          this.Product = new EntityClasses.SimpleProductClass(odataObject.Product);
        } else {
          var className: string = odataObject.Product['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.Product = new EntityClasses[className](odataObject.Product)
          }
        }

      
      }
  }

  /**
   * GetStockStatusResponse entity class.
   */
  export class GetStockStatusResponseExtensionClass implements IGetStockStatusResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StockStatus: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.StockStatus = odataObject.StockStatus;
              
      }
  }

  /**
   * CustomerWarehouseEntity entity class.
   */
  export class CustomerWarehouseEntityExtensionClass implements ICustomerWarehouseEntity {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public AvailableWarehouse: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public WarehouseName: string;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public ExtensionProperties: Entities.CommerceProperty[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.AvailableWarehouse = odataObject.AvailableWarehouse;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.WarehouseName = odataObject.WarehouseName;
              
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.ExtensionProperties = undefined;
        if (odataObject.ExtensionProperties) {
          this.ExtensionProperties = [];
          for (var i = 0; i < odataObject.ExtensionProperties.length; i++) {
            if (odataObject.ExtensionProperties[i]) {
        if (odataObject.ExtensionProperties[i]['@odata.type']) {
          var className: string = odataObject.ExtensionProperties[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.ExtensionProperties[i] = new EntityClasses[className](odataObject.ExtensionProperties[i])
          }
        } else {
          this.ExtensionProperties[i] = new EntityClasses.CommercePropertyClass(odataObject.ExtensionProperties[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.ExtensionProperties[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * StockHoldStatusEntity entity class.
   */
  export class StockHoldStatusEntityExtensionClass implements IStockHoldStatusEntity {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public Quantity: number;
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public InventHoldStatus: string;
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.Quantity = (odataObject.Quantity) ? parseFloat(odataObject.Quantity) : 0;
              
            // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
            this.InventHoldStatus = odataObject.InventHoldStatus;
              
      }
  }

  /**
   * GetCustomerWarehousesResponse entity class.
   */
  export class GetCustomerWarehousesResponseExtensionClass implements IGetCustomerWarehousesResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public AvailableWarehouses: 
            ICustomerWarehouseEntity[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.AvailableWarehouses = undefined;
        if (odataObject.AvailableWarehouses) {
          this.AvailableWarehouses = [];
          for (var i = 0; i < odataObject.AvailableWarehouses.length; i++) {
            if (odataObject.AvailableWarehouses[i]) {
        if (odataObject.AvailableWarehouses[i]['@odata.type']) {
          var className: string = odataObject.AvailableWarehouses[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.AvailableWarehouses[i] = new EntityClasses[className](odataObject.AvailableWarehouses[i])
          }
        } else {
          this.AvailableWarehouses[i] = new CustomerWarehouseEntityExtensionClass(odataObject.AvailableWarehouses[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.AvailableWarehouses[i] = undefined;
            }
          }
        }
      
      }
  }

  /**
   * GetStockHoldStatusResponse entity class.
   */
  export class GetStockHoldStatusResponseExtensionClass implements IGetStockHoldStatusResponse {
      
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        public StockHoldStatuses: 
            IStockHoldStatusEntity[];
      
      // Navigation properties names
      
      /**
       * Construct an object from odata response.
       * @param {any} odataObject The odata result object.
       */
      constructor(odataObject?: any) {
      odataObject = odataObject || {};
        // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
        this.StockHoldStatuses = undefined;
        if (odataObject.StockHoldStatuses) {
          this.StockHoldStatuses = [];
          for (var i = 0; i < odataObject.StockHoldStatuses.length; i++) {
            if (odataObject.StockHoldStatuses[i]) {
        if (odataObject.StockHoldStatuses[i]['@odata.type']) {
          var className: string = odataObject.StockHoldStatuses[i]['@odata.type'];
          className = className.substr(className.lastIndexOf('.') + 1).concat("Class");
          if (EntityClasses[className]) {
            this.StockHoldStatuses[i] = new EntityClasses[className](odataObject.StockHoldStatuses[i])
          }
        } else {
          this.StockHoldStatuses[i] = new StockHoldStatusEntityExtensionClass(odataObject.StockHoldStatuses[i]);
        }
      
            } else {
              // @ts-ignore - TODO bug fix #23427261 - remove ts-ignore - https://microsoft.visualstudio.com/DefaultCollection/OSGS/_workitems/edit/23427261/
              this.StockHoldStatuses[i] = undefined;
            }
          }
        }
      
      }
  }
